// Default Sidebar Style
.sidebar {
  display: block;
  position: fixed;
  width: 100%;
  max-width: $navbar-vertical-width;
  top: 4.5rem;
  bottom: 0;
  z-index: $zindex-general + 10;
  transition: $navbar-vertical-transition;
  background-color: $card_color;
  @if ($navbar-vertical-shadow-enable == true) {
    // box-shadow: $navbar-vertical-shadow;
    @include shadow-mixin($box-shadow-inset);
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    padding: 1.1rem;
    svg {
      color: var(--#{$variable-prefix}primary);
    }
    .logo-title {
      margin-left: $spacer;
      color: var(--#{$variable-prefix}primary);
      margin-bottom: 0;
      transition: $navbar-vertical-transition;
      transform: translateX(0%);
      opacity: 1;
      color: $dark;
    }
  }
  .sidebar-header {
    border-bottom: $border-width solid tint-color($border-color, 20%);
    margin-bottom: $spacer * 0.5;
  }
  .sidebar-body {
    padding: $spacer;
    overflow: hidden;
  }
  .data-scrollbar {
    max-height: 92vh;
  }
}

.sidebar-default {
  .sidebar-list {
    display: block;
    position: static;
    .navbar-nav {
      .nav-item {
        .nav-link:not(.disabled) {
          display: flex;
          align-items: center;
          white-space: nowrap;
          i {
            &.icon {
              display: inline-flex;
            }
          }
          span {
            flex: 1;
            transition: color 300ms ease-in-out;
            margin-left: $spacer;
            transform: translateX(0);
            opacity: 1;
            color: var(--text_color);
          }
        }
        .nav-link {
          &.static-item {
            margin-bottom: 0rem;
            .default-icon {
              text-transform: uppercase;
              font-size: calc(1rem - 2px);
              letter-spacing: 0.18rem;
              font-weight: 400;
              color: $dark;
            }
          }
          i.sidenav-mini-icon {
            opacity: 0;
            display: none;
          }
        }
      }
    }
  }
  .sidebar-body {
    padding: 0;
    padding-right: $spacer;
  }
  &:not(.sidebar-mini) {
    @include sidebar-nav-item-start-spaceing; //default spacing 1rem by default $spacer
    @include sidebar-nav-item-varients(true) {
      .sub-nav {
        .nav-link {
          padding-left: 2rem;
        }
      }
    }
  }
  .navbar-nav {
    .static-item {
      .mini-icon {
        display: none;
      }
    }
    .nav-item {
      transition: color 400ms ease;
      position: relative;
      margin-top: 2px;
      .nav-link {
        text-transform: capitalize;
        &:not(.disabled) {
          .right-icon {
            transition: all 100ms ease-in-out;
          }
          color: $gray-600;
          &.active,
          &[aria-expanded="true"] {
            background-color: var(--icon_color);
            .right-icon {
              transition: all 100ms ease-in-out;
              transform: rotate(90deg);
            }
          }
          &.active {
            &[aria-expanded="false"] {
              .right-icon {
                transition: all 100ms ease-in-out;
                transform: rotate(0deg);
              }
            }
          }
          &:hover:not(.active):not([aria-expanded="true"]) {
            @include sidebar-nav-item-hover($primary);
          }
        }
      }
      .sub-nav {
        list-style: none;
        padding: 0;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .sidebar {
    .sidebar-toggle {
      right: 18px;
    }
  }
}
