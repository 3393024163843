.new-music {
  h6 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}
.recent-music {
  h6 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}
.top-music {
  h6 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}
