@import "../../../variables";
$dark: #60658a;
$primary: $cta_background !default;
$navbar-vertical-dark-bg: $dark !default;
$navbar-vertical-color-bg: $primary !default;

$navbar-vertical-width: 13.625rem !default;
$navbar-vertical-mini-width: 5rem !default;

$navbar-vertical-active-shadow: 0 10px 20px -10px rgba(darken($primary, 25%), 0.4);
$navbar-vertical-hover-shadow: null;

$navbar-vertical-shadow-enable: true;
$navbar-horizontal-shadow-enable: true;

$navbar-vertical-shadow: 0 0px 30px 0 rgba(darken($primary, 25%), 0.05);
$navbar-horizontal-shadow: 0 0px 30px 0 rgba(darken($primary, 25%), 0.05);

$navbar-vertical-transition: all 400ms ease;
$navbar-small-item: 0.625rem 0.75rem;
