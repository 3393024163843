.flet-datepickr {
  .flatpickr-months {
    .flatpickr-next-month {
      padding: 1px 10px;
    }
    .flatpickr-prev-month {
      padding: 1px 10px;
    }
    .flatpickr-current-month {
      padding: 0px;
    }
  }
  .flatpickr-calendar.inline {
    top: -12px;
  }
  .flatpickr-calendar {
    &:before {
      display: none;
    }
  }
  .flatpickr-innerContainer {
    display: block;
    padding-top: 3px;
  }
}
.flet-datepickr {
  .flatpickr-innerContainer {
    display: block;
  }
}
.flet-datepickr {
  .flatpickr-calendar.inline {
    box-shadow: none;
  }
  .flatpickr-current-month {
    .flatpickr-monthDropdown-months {
      &:hover {
        background: transparent;
      }
    }
  }

  .numInputWrapper {
    &:hover {
      background: transparent;
    }
  }
  .flatpickr-months {
    .flatpickr-next-month {
      &:hover {
        svg {
          fill: $primary;
        }
      }
    }
    .flatpickr-prev-month {
      &:hover {
        svg {
          fill: $primary;
        }
      }
    }
  }
}
.flatpickr-innerContainer {
  padding-top: 0.5rem !important;
}

.inln-date {
  .flatpickr-calendar {
    box-shadow: none;
    margin: 0 auto;
    padding: 0;
  }
  .dayContainer {
    width: 100%;
    min-width: auto;
  }
  .flatpickr-days {
    width: 100%;
  }
}

.flatpickr-day {
  border-radius: 5px;
}
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: $primary;
  box-shadow: none;
  color: $white;
  border-color: $primary;
}
@media (max-width: 1460px) {
  .flet-datepickr {
    .flatpickr-calendar.inline {
      left: -1.875rem;
    }
  }
}

//calender page
.bootstrap-select {
  &.calender-select {
    width: auto !important;
    .dropdown-toggle {
      background: $primary;
      color: $white;
      line-height: 24px;
      width: auto;
      padding: 0.375rem 0.75rem;
      height: 2.5rem;
      margin-right: 20px;

      &::after {
        margin-left: 10px;
      }
      .filter-option-inner-inner {
        color: $white;
      }
    }
  }
}
.flatpickr-calendar {
  &:before {
    border-width: 5px;
    margin: 0 -5px;
    display: none;
  }
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
  margin: 0 auto;
  box-shadow: none;
}
.flatpickr-calendar {
  box-shadow: none;
}
@media (max-width: 1399px) {
  .flatpickr-calendar {
    width: 265px !important;
  }
}
@media (max-width: 1300px) {
  .flatpickr-calendar {
    width: 248px !important;
  }
  .flatpickr-current-month {
    left: 7.5%;
  }
}
@media (max-width: 1300px) {
  .flatpickr-calendar {
    width: 270px !important;
  }
}

@media (max-width: 767px) {
  .flatpickr-calendar {
    width: 250px !important;
  }
}
@media (max-width: 320px) {
  .flatpickr-calendar {
    width: 200px !important;
  }
}
