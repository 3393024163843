.body-chat {
  .iq-top-navbar {
    position: relative;
  }
  .content-page {
    @media (min-width: 992px) {
      min-height: unset;
      height: calc(100vh - 56px);
      overflow: hidden !important;
    }
    @media (max-width: 991px) {
      min-height: unset;
    }
  }
  footer {
    &.iq-footer {
      margin-top: 0 !important;
    }
  }
}

#chatScreen {
  height: calc(100vh - 56px);

  @media (max-width: 991px) {
    height: calc(100vh - 100px);
  }

  .container-fluid {
    padding: 30px 30px;
    height: 100%;
  }

  .right_chat_area {
    display: flex;
    flex-direction: column;
    width: calc(100% - 400px);
  }

  @media (min-width: 1025px) {
    .container-fluid {
      padding: 30px;
      display: flex;
    }
    .right_chat_area {
      padding: 0 0 0 25px;
    }
  }
  @media (max-width: 1024px) {
    .container-fluid {
      padding: 20px 15px;
      display: block;
    }
  }
}

.LeftSideScreenformessaging {
  width: 400px;
  border: 2px solid var(--border_color);
  box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  height: 100%;
  padding: 12px;

  @media (max-width: 1024px) {
    width: 100% !important;
  }

  .insideCard {
    position: relative;
    background: var(--primary_color);
    border-radius: 12px;
    border: 1px solid var(--border_color);
    height: 100%;
    @media (min-width: 1025px) {
      padding: 5px 20px 20px 20px;
    }
    @media (max-width: 1024px) {
      padding: 5px 10px 10px 10px;
    }
  }

  .profile-referral-setting-tab {
    &.border-bottom {
      border-color: var(--border_color) !important;
    }
    .TabActive {
      font-weight: 800;
    }
  }

  // .custom_box_search_root {
  //   input {
  //     border: 0;
  //     background: var(--primary_color) !important;
  //   }
  // }

  .chat_card2 {
    border-radius: 8px;
    width: 100%;
    border: 1px solid var(--border_color);
    padding: 10px;

    &.active {
      background-color: var(--border_color);
    }

    .badge {
      background: var(--text_color);
      color: var(--background_color);
    }

    .avatar-40 {
      @media (max-width: 1024px) {
        height: 34px !important;
        min-width: 34px !important;
        width: 34px !important;
      }
    }
  }
}

.list-friends {
  height: calc(100% - 120px);
  overflow: auto;
  overflow-x: hidden;
  padding-right: 10px !important;
}

.rightsidechatuserlist {
  background: var(--primary_color);
  box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  position: relative;
}

.chat-room {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  padding-right: 15px;

  @media (min-width: 1025px) {
    height: calc(100% - 130px);
    margin: 15px 0;
  }
  @media (min-width: 992px) and (max-width: 1024px) {
    height: calc(100vh - 255px);
    margin: 10px 0;
  }
  @media (max-width: 991px) {
    height: calc(100vh - 300px);
    margin: 10px 0;
  }

  .infoSec {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    * {
      text-align: center;
      line-height: normal;
    }
    h3 {
      font-weight: bold;
      @media (min-width: 992px) {
        font-size: 24px;
      }
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
    p {
      margin: 0;
      @media (min-width: 992px) {
        font-size: 16px;
      }
      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
  }

  .edit_class {
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 767px) {
      font-size: 10px;
    }

    .primary-select {
      padding: 0 !important;
      .btn {
        padding: 0 !important;
      }
    }
    @media (max-width: 767px) {
      .more_vert {
        font-size: 18px;
      }
    }
    .primary-select.dropdown {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
}

h3.side-line {
  overflow: hidden;
  text-align: left;
  color: var(--cta_color);
  position: sticky;
  position: -webkit-sticky;
  top: 125px !important;
}

h3.side-line:before,
h3.side-line:after {
  background-color: var(--border_color);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h3.side-line.line-color-theme:before,
h3.side-line.line-color-theme:after {
  background-color: var(--icon_color) !important;
}

h3.side-line:before {
  right: 2.5em;
  margin-left: -50%;
}

h3.side-line:after {
  left: 2.5em;
  margin-right: -50%;
}

h3.side-line.line-color-theme {
  position: relative !important;
  top: 0 !important;
}

@mixin galleryPhotosSize {
  object-fit: cover;
  object-position: center;

  @media (min-width: 768px) {
    height: 210px !important;
  }
  @media (max-width: 767px) {
    height: 170px !important;
  }
}

.chat_outgoing-wrap,
.chat_incoming-wrap {
  &:not(.withImage) {
    width: fit-content;

    .chat_incoming,
    .chat_outgoing {
      width: fit-content;
    }
  }
}

.chat_incoming {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--primary_color);
  border: 1px solid var(--border_color);
  border-radius: 0px 12px 12px 12px;
  color: var(--text_color);
}

.chat_outgoing {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border_color);
  background: var(--text_color);
  border-radius: 0px 12px 12px 12px;
  color: var(--cta_color);
  margin-left: auto;
  overflow: hidden;
  transition: 0.15s ease-in-out;
  transition-delay: 0.2s;

  .edit_class {
    .btn {
      padding: 0 !important;
    }
  }

  a {
    &:not(.dropdown-item) {
      color: var(--cta_color) !important;
    }
  }
}

.chat_incoming,
.chat_outgoing {
  .galleryPhotos {
    position: relative;
    width: 100%;
    overflow: hidden;

    .ReactGridGallery {
      width: 100%;
    }
    .react-photo-gallery--gallery {
      // border-radius: 0 10px 10px 10px;
      overflow: hidden;
      padding: 2px;
      // width: calc(100% - 4px);
      & > div {
        gap: 2px;
      }
      img {
        width: 100% !important;
        margin: 0 !important;
        border-radius: 0 12px 12px 12px;
        @include galleryPhotosSize;
      }
    }
  }

  .share_topic_img {
    border-radius: 0 0 12px 12px;
    @include galleryPhotosSize;
  }

  .innerContent {
    &.noMSgContent {
      padding: 0px 5px !important;
    }
    &:not(.noMSgContent) {
      @media (min-width: 768px) {
        padding: 8px 15px;
      }
      @media (max-width: 767px) {
        padding: 6px 10px;
      }
    }
  }

  .moreOptions {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 12px 12px 0;
    opacity: 0;
    transition: 0.15s ease-in-out;
    pointer-events: none;

    &:not(.onImage) {
      right: -3px;
      top: 0;
      height: 41px;
      width: 35px;
      background: linear-gradient(-90deg, var(--text_color) 80%, transparent);

      @media (min-width: 768px) {
        height: 41px;
        width: 35px;
      }
      @media (max-width: 767px) {
        height: 29px;
        width: 25px;
      }
    }

    &.onImage {
      top: 10px;
      right: 0;
      border-radius: 50%;
      background: rgba(var(--background_color_rgb), 0.6);

      @media (min-width: 768px) {
        height: 30px;
        width: 30px;
      }
      @media (max-width: 767px) {
        height: 24px;
        width: 24px;
      }

      .more_vert {
        color: var(--text_color) !important;
      }
    }
  }

  &:hover {
    overflow: visible;

    .moreOptions {
      pointer-events: all;
      opacity: 1;

      &:not(.onImage) {
        right: 0;
      }
      &.onImage {
        right: 10px;
      }
    }
  }
}

.chat_incoming {
  .moreOptions {
    &:not(.onImage) {
      background: linear-gradient(-90deg, var(--primary_color) 80%, transparent);
      .more_vert {
        color: var(--text_color) !important;
      }
    }

    &.onImage {
      background: rgba(var(--background_color_rgb), 0.6);
    }
  }
}

.chat_outgoing {
  .moreOptions {
    &:not(.onImage) {
      background: linear-gradient(-90deg, var(--text_color) 80%, transparent);
    }

    &.onImage {
      background: rgba(var(--background_color_rgb), 0.6);
    }
  }
}

.chatTime {
  p {
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
}

.chatUploaded-img {
  position: absolute;
  bottom: 55px;
  z-index: 99;
  max-width: 100%;
  background: var(--text_color);
  border-radius: 16px;

  @media (min-width: 1025px) {
    left: 25px;
  }
  @media (min-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 15px;
  }

  .imgGrp {
    position: relative;
    max-height: 300px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      border: 1px solid #e3e3e3 !important;
    }

    .ReactGridGallery {
      .ReactGridGallery_tile {
        border-radius: 6px;
        overflow: hidden;
      }
    }

    @media (min-width: 768px) {
      margin-top: 20px;
    }
    @media (max-width: 767px) {
      margin-top: 15px;
    }
  }
}
