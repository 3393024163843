.card {
  background: $card_color-bg;
  .card-header,
  .card-footer {
    background-color: $card_color-bg;
    border-bottom-color: $border-color;
  }
}
.card-post-toolbar {
  .dropdown-menu {
    a {
      border-color: $border-color;
      &:hover {
        background-color: $body-bg;
        color: $primary;
      }
    }
    .dropdown-item {
      color: $body-color;
    }
  }
  .dropdown-toggle {
    i {
      color: $body-color;
    }
  }
}

.card-footer {
  border-top: 1px solid $border-color;
}
