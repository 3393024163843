.iq-top-navbar {
  background: $card_color-bg;
  .iq-navbar {
    background: $card_color-bg;
  }
  &.menu-sticky {
    background: $card_color-bg;
  }
}
.iq-navbar-logo {
  a {
    span {
      color: $body-color;
    }
  }
}
.wrapper-menu {
  color: $body-color;
}
.iq-search-bar {
  .search-input {
    background: $body-bg;
    border-color: $border-color;
    color: $body-color;
  }
}
.navbar-collapse {
  background-color: $card_color-bg;
}
