.custom-card-title {
  @media (max-width: 768px) {
    height: auto !important;
  }

  &.ico-card-title {
    border-bottom: 1px solid var(--primary_color);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      font-size: 14px;
      flex-direction: column;
      align-items: start;
      gap: 12px;
    }

    .status-tag {
      position: relative;
      padding: 8px 14px;
      border-radius: 35px;
      color: var(--text_color);
      background-color: var(--border_color);
      box-shadow: 0px 2px 4px 0px #00000040;

      @media (max-width: 768px) {
        padding: 4px 8px;
      }
    }
    &.ongoing {
      background: var(--primary_color) !important;
      .status-tag {
        background-color: $green_color !important;
        color: #ffffff !important;
      }
    }
    &.upcoming {
      background: var(--primary_color) !important;
    }
    &.closed {
      background: var(--primary_color) !important;
      .title {
        opacity: 0.6;
      }
    }
  }
}

.ico_detail_list {
  position: relative;

  .mb {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  p {
    color: var(--text_color);
    span {
      color: var(--text_color);
    }
  }
}

.ongoingIcoDetail {
  background: var(--background_color) !important;

  @media (min-width: 768px) {
    padding: 20px;
  }
  @media (max-width: 767px) {
    padding: 10px;
  }

  .IcoImage {
    @media (max-width: 767px) {
      height: 40px;
      width: 40px;
    }
  }

  .font20 {
    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }
  p {
    color: var(--text_color);
  }

  .progress-bar {
    position: relative;
    height: 4px;
    width: 100%;
    background: var(--primary_color);
    border-radius: 12px;
    display: block;
    overflow: hidden;
    margin: 20px 0 0 0;

    .progress-line {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: var(--text_color);
      border-radius: 12px;
    }
  }

  .dotList {
    position: relative;
    border-bottom: 1px dashed var(--border_color);

    p {
      background: var(--background_color) !important;
      margin-bottom: -10px;

      &:nth-child(2) {
        padding-left: 10px;
      }

      .tag {
        position: relative;
        padding: 4px 6px;
        border-radius: 4px;
        background-color: $green_color !important;
        color: #ffffff !important;
        font-size: 10px;
      }
    }
  }
  @media (min-width: 768px) {
    .sidebarButtonFullWidth {
      &.btn.btn-primary {
        font-size: 16px !important;
      }
    }
  }
  @media (max-width: 767px) {
    .sidebarButtonFullWidth {
      &.btn.btn-primary {
        padding: 6px 3px !important;
      }
    }
  }
}

.icoLockup-tab {
  background: var(--primary_color);
  border: 1px solid var(--border_color);
  border-radius: 8px;
  overflow: hidden;

  .TabActive {
    font-weight: 700;
  }
}

.ico_conf_list {
  position: relative;

  .conf_list {
    padding-top: 10px;
    padding-bottom: 10px;
    @media (min-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--border_color);
    }

    svg {
      height: 20px;
      width: 20px;
      color: var(--text_color);
    }
  }
}

.icoCreateForm {
  .font14 {
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  .font16 {
    @media (max-width: 768px) {
      font-size: 14px !important;
    }
  }
  .card-header:first-child {
    background: transparent !important;
  }

  // .form-check-input {
  //   min-width: 16px;
  // }

  .backBtn-withArrow {
    .icon {
      svg {
        background-color: var(--background_color);
        border-radius: 50%;
        @media (min-width: 768px) {
          height: 24px;
          width: 24px;
        }
        @media (max-width: 767px) {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

.uploadImageIcon {
  top: 5px;
  left: 8px;
}
