.iq-footer {
  background-color: $card_color-bg !important;
  ul {
    li {
      a {
        color: $body-color;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
