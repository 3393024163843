.AuthPage {
  position: relative;
  background: var(--background_color);
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    overflow: hidden;
  }
  @media (max-width: 991px) {
    padding: 30px 0;
  }

  & > .container {
    @media (min-width: 1400px) {
      height: calc(100vh - 90px);
      max-width: 1320px;
    }
    @media (min-width: 992px) and (max-width: 1399px) {
      height: calc(100vh - 60px);
    }

    & > .row {
      height: 100%;
    }
  }

  .AuthPage-shapes {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    overflow: hidden;

    .main-BgImg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
      display: block;
      pointer-events: none;
    }

    &::before {
      top: 50%;
      left: 5%;
      transform: translate(-5%, -50%);
      background: linear-gradient(
        290.12deg,
        rgba(189, 0, 255, 0.5) 10.16%,
        rgba(82, 0, 255, 0.5) 62.62%
      );
      filter: blur(100px);
    }

    &::after {
      top: 50%;
      right: 5%;
      transform: translate(-5%, -50%);
      background: linear-gradient(
        289.03deg,
        rgba(189, 0, 255, 0.5) 45.39%,
        rgba(82, 0, 255, 0.5) 89.44%
      );
      filter: blur(100px);
    }
  }

  .sideCard-wrap {
    position: relative;
    background-color: #360489;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 4px 8px #835bd8;
    border: 1px solid #ffffff;
    z-index: 10;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        125.1deg,
        rgba(255, 255, 255, 0.2) 2.04%,
        rgba(44, 0, 135, 0.2) 97.17%
      );
      display: block;
      pointer-events: none;
    }

    .sideCard-BgImg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      pointer-events: none;
    }
  }

  .form-check-input:not(:checked) {
    background: transparent !important;
  }
}

.sideCard {
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  z-index: 1;
  padding: 30px 10%;

  @media (min-width: 1400px) {
    height: calc(100vh - 90px);
  }
  @media (min-width: 992px) and (max-width: 1399px) {
    height: calc(100vh - 60px);
  }
  @media (max-width: 575px) {
    padding: 20px;
  }

  .title {
    position: relative;
    line-height: normal;
    text-align: center;

    @media (min-width: 1400px) {
      font-size: 44px;
    }
    @media (max-width: 1399px) {
      font-size: 32px;
    }
    @media (max-width: 991px) {
      font-size: 24px;
    }
  }

  .googleSignIn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (min-width: 1400px) {
      margin: 10px 0 30px 0;
    }
    @media (max-width: 1399px) {
      margin: 10px 0 15px 0;
    }

    .googleBtn {
      display: flex;
      align-items: center;
      gap: 14px;
      font-weight: bold;
      background-color: var(--text_color);
      color: var(--background_color);
      border-radius: 12px;
      border: none;
      transition: 0.25s ease-in-out;

      &:hover {
        color: var(--text_color);
        background-color: var(--primary_color);
      }

      @media (min-width: 1400px) {
        padding: 6px 12px;
        font-size: 16px;
      }
      @media (max-width: 1399px) {
        padding: 6px 12px;
        font-size: 14px;
      }
      @media (max-width: 767px) {
        padding: 6px 12px !important;
        border-radius: 6px;
        gap: 8px;
      }

      svg {
        @media (min-width: 1400px) {
          font-size: 20px;
        }
        @media (max-width: 1399px) {
          font-size: 20px;
        }
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }
    }

    .orText {
      font-weight: bold;
      color: var(--text_color);

      @media (min-width: 1400px) {
        font-size: 20px;
        margin: 30px 0 0 0;
      }
      @media (max-width: 1399px) {
        font-size: 16px;
        margin: 15px 0 0 0;
      }
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
}

.AuthInfo {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;

  .logo {
    height: auto;

    @media (min-width: 1400px) {
      max-width: 365px;
    }
    @media (max-width: 1399px) {
      max-width: 265px;
    }
    @media (max-width: 991px) {
      max-width: 200px;
    }
    @media (max-width: 575px) {
      max-width: 180px;
    }
  }

  .infoContent {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (min-width: 1400px) {
      margin-top: 20px;
    }
    @media (max-width: 1399px) {
      margin-top: 20px;
    }
    @media (max-width: 991px) {
      margin-top: 20px;

      margin-bottom: 30px;
    }

    h1 {
      font-size: 36px;
      line-height: normal;

      @media (min-width: 1400px) {
        font-size: 36px;
        margin-bottom: 25px;
      }
      @media (max-width: 1399px) {
        font-size: 28px;
        margin-bottom: 20px;
      }
      @media (max-width: 991px) {
        font-size: 22px;
        margin-bottom: 14px;
      }

      span {
        display: block;
      }
    }

    p {
      font-size: 24px;
      line-height: normal;

      @media (min-width: 1400px) {
        font-size: 24px;
        max-width: 330px;
      }
      @media (max-width: 1399px) {
        font-size: 20px;
        max-width: 330px;
      }
      @media (max-width: 991px) {
        font-size: 14px;
        max-width: 200px;
      }
    }
  }
}

.text_signin {
  font-size: 20px;

  @media (max-width: 991px) {
    font-size: 12px;
  }
}

.text_forgot_password {
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 991px) {
    font-size: 12px;
  }
}

.link_text {
  text-decoration: none;
}
.link_text:hover {
  text-decoration: underline;
}
.forgot_pwd {
  @media (min-width: 991px) {
    height: 60vh !important;
  }
}
