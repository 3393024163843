.form-control {
  color: $body-color;
  background-color: $card_color-bg;
  border-color: $border-color;
}
.form-select {
  color: $body-color;
  background-color: $card_color-bg;
  border-color: $border-color;
}
.input-group-text {
  background-color: $card_color-bg;
  border-color: $border-color;
}
.stepwizard-row {
  a {
    &.btn {
      color: $body-color;
      background-color: $body-bg;
    }
  }
}
#top-tabbar-vertical {
  li {
    a {
      color: $body-color;
      background-color: $body-bg;
    }
  }
}
