// === Custom Table CSS Start ===

.customTable {
  position: relative;
  border-color: var(--border_color);

  &.withoutBorder {
    border: none !important;
  }

  .table-responsive {
    border-radius: 10px;
  }
  table {
    position: relative;
    display: table !important;
    thead {
      tr {
        th {
          white-space: nowrap;
          color: var(--heading_color);
          padding: 8px 15px;
        }
      }
    }
    tbody {
      border-top-width: 1px !important;
      border-color: var(--border_color) !important;
      tr {
        td {
          white-space: nowrap;
          color: var(--text_color);
          font-size: 14px;
          padding: 10px 15px;
          border-color: var(--border_color);
          vertical-align: middle;
        }
        &:last-child {
          td {
            border-bottom: 0 !important;
          }
        }
      }
    }
    .noData-Img {
      position: relative;
      max-width: 60%;
    }
  }
  &:not(.withoutBorder) {
    table {
      thead {
        tr {
          th {
            background-color: var(--primary_color);
          }
        }
      }
      tbody {
        tr {
          &:nth-child(even) {
            background: var(--background_color);
          }
        }
      }
    }
  }
}

.transaction_claimed_popup {
  &.modal-dialog {
    width: 555px !important;
  }
  .modal-content {
    .modal-body {
      border-bottom-left-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
    }
  }
  &.modal-dialog-centered {
    min-height: auto !important;
  }
}

// === Custom Table CSS End ===
