@each $breakpoint in map-keys($grid-breakpoints) {
  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $name, $value in $spacers {
      .me#{$infix}-#{$name} {
        margin-right: 0 !important;
      }
      .ms#{$infix}-#{$name} {
        margin-left: 0 !important;
      }
      .pe#{$infix}-#{$name} {
        padding-right: 0 !important;
      }
      .ps#{$infix}-#{$name} {
        padding-left: 0 !important;
      }
    }
  }
}
