.iq-maintenance {
  img {
    width: 40%;
  }
}

@include media-breakpoint-down(md) {
  .iq-maintenance {
    img {
      width: 70%;
    }
  }
  .iq-error {
    img {
      padding: 0px 3.125rem;
    }
  }
}
