$coverImage_up_md: 190px;
$coverImage_down_md: 150px;

.communityCard {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-radius: 13px;
  border: 1px solid var(--border_color);
  background: var(--primary_color);
  overflow: hidden;
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 12px 0 var(--border_color);
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 40px;
    width: 100%;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.4) 95.42%);
    display: block;
  }

  .coverImg {
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      height: $coverImage_up_md;
    }
    @media (max-width: 767px) {
      height: $coverImage_down_md;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 40px;
      width: 100%;
      background: linear-gradient(0deg, var(--primary_color) 25%, transparent 100%);
      display: block;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .innerContent {
    position: relative;

    @media (min-width: 768px) {
      padding: 0 15px 45px 15px;
      height: calc(100% - #{$coverImage_up_md});
    }
    @media (max-width: 767px) {
      padding: 0 10px 40px 10px;
      height: calc(100% - #{$coverImage_down_md});
    }

    .cardTitle-wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: 768px) {
        height: 48px;
      }
      @media (max-width: 767px) {
        height: 48px;
      }
    }

    .cardTitle {
      font-weight: bold;
      color: var(--heading_color);
      margin-bottom: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .iconList-wrap {
      gap: 15px;
      margin: 15px 0 10px 0;
    }

    .iconList {
      font-weight: bold;
      gap: 5px;
      font-size: 12px;
      line-height: normal;

      .svgIcon {
        height: 16px;
        width: 16px;
      }
    }

    .tagLine {
      position: relative;
      color: var(--text_color);
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 16px;
      }
      @media (max-width: 767px) {
        font-size: 10px;
        line-height: 12px;
      }
    }

    .communityType {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      .paidPrice {
        font-weight: bold;
        color: var(--text_color);
      }

      @media (min-width: 768px) {
        padding: 10px 12px;
        .paidPrice {
          font-size: 14px;
        }
      }
      @media (max-width: 767px) {
        padding: 8px 12px;
        .paidPrice {
          font-size: 12px;
        }
      }
    }
  }
}
