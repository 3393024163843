.ratio {
  position: relative;
  width: 100%;
  &::before {
    display: block;
    padding-top: 50%;
    content: "";
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
