#container-inside {
  #circle-small {
    right: -9.375rem;
    left: unset;
  }
  #circle-medium {
    right: -18.75rem;
    left: unset;
  }
  #circle-large {
    right: -28.125rem;
    left: unset;
  }
  #circle-xlarge {
    right: -37.5rem;
    left: unset;
  }
  #circle-xxlarge {
    right: -46.875rem;
    left: unset;
  }
}

.iq-social-media {
  float: left;
}
