@mixin LevelImage($color, $bg, $icon) {
  .levelTitle {
    color: $color;
  }
  .userImage {
    background-image: url($bg);
    &::before {
      background-image: url($icon);
    }
  }
}

.profile-page {
  position: relative;

  .profile-setting-tab-new {
    top: 57px;
    @media (min-width: 768px) and (max-width: 991px) {
      top: 63px;
    }
    @media (max-width: 767px) {
      top: 55px;
    }
  }
}

//====== Profile Top Section CSS Start ======

.myProfile {
  .coverPic-wrap {
    position: relative;
    .coverPic {
      width: 100%;
      object-fit: cover;
      @media (min-width: 992px) {
        height: 310px !important;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        height: 220px !important;
      }
      @media (max-width: 767px) {
        height: 180px !important;
      }
    }
  }

  .coverPic_actions {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
  }

  .userDetail {
    position: relative;
    background: var(--primary_color);
    border-radius: 0 0 12px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pt {
      @media (min-width: 768px) {
        padding-top: 15px;
      }
      @media (max-width: 767px) {
        padding-top: 10px;
      }
    }

    @media (min-width: 1200px) {
      padding: 0 65px 20px 65px;
    }

    @media (max-width: 1200px) {
      padding: 0 45px 20px 45px;
    }

    @media (max-width: 991px) {
      padding: 0 25px 20px 25px;
      flex-direction: column;
      align-items: flex-start;
    }

    @media (max-width: 767px) {
      padding: 0 15px 15px 15px;
    }

    .user-titleText {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .userImage-level {
      position: relative;

      @media (min-width: 768px) {
        margin-top: -60px;
      }
      @media (max-width: 767px) {
        margin-top: -30px;
      }

      .levelTitle {
        position: relative;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-style: italic;

        @media (min-width: 768px) {
          font-size: 12px;
          margin: 10px 0 0 0;
        }
        @media (max-width: 767px) {
          font-size: 10px;
          margin: 8px 0 0 0;
        }
      }

      .userImage {
        position: relative;
        display: flex;
        align-items: center;

        .avatar {
          @include AvatarCommon(100px, 86px, 66px);
          background: var(--background_color);
          border: none !important;
        }

        .filesDropzone {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          color: var(--text_color);
          background: rgba(0, 0, 0, 0.5);
          opacity: 0;
          pointer-events: none;
          transition: 0.3s all;

          @media (min-width: 768px) {
            inset: 10px;
          }
          @media (max-width: 767px) {
            inset: 8px;
          }

          i {
            color: currentColor !important;
            @media (min-width: 768px) {
              font-size: 32px;
            }
            @media (max-width: 767px) {
              font-size: 16px;
            }
          }
        }

        &:hover {
          .filesDropzone {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &.level0 {
        .levelTitle {
          display: none;
        }
        .userImage {
          .avatar {
            border: 2px solid var(--primary_color);
          }
        }
      }
      &:not(.level0) {
        .userImage {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          @media (min-width: 768px) {
            padding: 11px;
          }
          @media (max-width: 767px) {
            padding: 8px;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            z-index: 1;

            @media (min-width: 768px) {
              height: 30px;
              width: 30px;
            }
            @media (max-width: 767px) {
              height: 24px;
              width: 24px;
            }
          }
        }
      }

      &.level1 {
        @include LevelImage(
          #ffef62,
          "../../images/userlvlbg/lvl1-bg.png",
          "../../images/userlvlbadge/lvl1-badge.png"
        );
      }
      &.level2 {
        @include LevelImage(
          #00ffc6,
          "../../images/userlvlbg/lvl2-bg.png",
          "../../images/userlvlbadge/lvl2-badge.png"
        );
      }
      &.level3 {
        @include LevelImage(
          #ff97a7,
          "../../images/userlvlbg/lvl3-bg.png",
          "../../images/userlvlbadge/lvl3-badge.png"
        );
      }
      &.level4 {
        @include LevelImage(
          #5dddff,
          "../../images/userlvlbg/lvl4-bg.png",
          "../../images/userlvlbadge/lvl4-badge.png"
        );
      }
      &.level5 {
        @include LevelImage(
          #e49ae2,
          "../../images/userlvlbg/lvl5-bg.png",
          "../../images/userlvlbadge/lvl5-badge.png"
        );
      }
      &.level6 {
        @include LevelImage(
          #cf7273,
          "../../images/userlvlbg/lvl6-bg.png",
          "../../images/userlvlbadge/lvl6-badge.png"
        );
      }
      &.level7 {
        @include LevelImage(
          #f3f3f3,
          "../../images/userlvlbg/lvl7-bg.png",
          "../../images/userlvlbadge/lvl7-badge.png"
        );
      }
      &.level8 {
        @include LevelImage(
          #48cdbc,
          "../../images/userlvlbg/lvl8-bg.png",
          "../../images/userlvlbadge/lvl8-badge.png"
        );
      }
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      text-align: left;

      & > * {
        line-height: normal;
        color: var(--text_color);
        word-break: break-all;
        @include LineClamp(1);
        max-width: 400px;
      }

      .userTitle {
        font-weight: 700;

        @media (min-width: 768px) {
          font-size: 24px !important;
        }
        @media (max-width: 767px) {
          font-size: 18px !important;
        }
      }

      .userName {
        font-size: 12px;
        margin: 3px 0 0 0;
      }

      .userTagline {
        margin: 6px 0 0 0;

        @media (min-width: 768px) {
          font-size: 14px;
        }
        @media (max-width: 767px) {
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .userDetails-actions {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: end;
    }
  }
}

//====== Profile Top Section CSS End ======

//====== About Section CSS Start ======

.aboutGrid-details {
  .row {
    .col {
      &:nth-child(1) {
        .border-col {
          border-top: 1px solid var(--border_color);
        }
      }
      @media (min-width: 992px) {
        &:nth-child(2) {
          .border-col {
            border-top: 1px solid var(--border_color);
          }
        }
      }
    }
  }

  .border-col {
    border-bottom: 1px solid var(--border_color);
  }
}

.profielSocial-media {
  position: relative;
  svg {
    @media (min-width: 768px) {
      height: 30px;
      width: 30px;
    }
    @media (max-width: 767px) {
      height: 22px;
      width: 22px;
    }
  }
}

.lockUnlockIcon {
  cursor: pointer;

  @media (min-width: 768px) {
    height: 24px;
    width: 24px;
  }
  @media (max-width: 767px) {
    height: 20px;
    width: 20px;
  }
}

//====== About Section CSS End ======

//====== Friends Card CSS Start ======

.friendCard {
  position: relative;
  background: var(--background_color);
  border-radius: 20px;
  box-shadow: 2px 4px 7px 0 rgba(0, 0, 0, 0.25);
  height: 100%;

  .friends-coverImg {
    position: relative;
    height: 130px;
    width: 100%;
    border-radius: 20px 20px 0 20px;
    overflow: hidden;
    display: flex;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      object-position: center;
    }
  }

  .friends-detail {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 15px 15px 15px;

    .friend-titleText {
      position: relative;
      display: flex;
      @media (min-width: 768px) {
        gap: 12px;
      }
      @media (max-width: 767px) {
        gap: 8px;
      }
    }

    .avatar {
      position: relative;
      @include AvatarCommon(60px, 50px, 50px);
      background: var(--primary_color);

      @media (min-width: 992px) {
        margin-top: -25px;
      }
      @media (max-width: 991px) {
        margin-top: -20px;
      }
    }

    .friend-info {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: 10px;

      h4 {
        margin-bottom: 4px;
      }

      & > * {
        line-height: normal;
        @include LineClamp(1);
      }
    }

    .primary-select {
      padding: 10px 0 0 0 !important;

      .btn {
        padding: 0 !important;
      }
    }

    .dropdown {
      .dropdown-menu {
        left: auto !important;
        right: 0 !important;
      }
    }
  }

  .friendCard-action {
    position: relative;
    padding: 0 15px 15px 15px;
    .btn {
      min-height: 32px;
    }

    .messageBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      span {
        display: flex;
        align-items: center;
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}

//====== Friends Card CSS End ======

//====== Profile Notification Page CSS Start ======

.profileNotifi-page {
  .form-switch {
    padding: 0;
    @media (min-width: 992px) {
      align-items: center;
      justify-content: center;
    }

    input {
      margin: 0 !important;
    }
  }
}

.profileSecurity-page {
  .form-switch {
    // padding: 0;

    input {
      margin: 0 !important;
    }
  }
}

//====== Profile Notification Page CSS End ======

//====== Profile TFA Modal CSS Start ======

.modal-dialog {
  &.tfaModal {
    @media (min-width: 1481px) {
      width: 768px !important;
    }
    @media (max-width: 1480px) {
      width: 665px !important;
    }
  }
}

.tfa-modal {
  .stepper {
    margin-bottom: 15px;
  }

  .form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 250px;
  }
}

.selectType {
  position: relative;

  p {
    font-weight: bold;
    color: var(--text_color);

    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 25px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  .storeImg {
    width: 100%;

    @media (min-width: 1481px) {
      max-width: 260px;
    }
    @media (max-width: 1480px) {
      max-width: 180px;
    }
  }
}

.scanQr {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;

  p {
    font-weight: bold;
    color: var(--text_color);
    margin: 0;

    @media (min-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .qrImg {
    width: 110px;
    height: 110px;
  }

  .wordBreak {
    word-break: break-all;
  }
}

.enterToken {
  position: relative;

  p {
    font-weight: bold;
    color: var(--text_color);

    @media (min-width: 768px) {
      font-size: 16px;
      margin-bottom: 25px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  .tokenField {
    max-width: 380px;
    margin: 0 auto;
  }
}

//====== Profile TFA Modal CSS End ======

.line-clamp-1 {
  @include LineClamp(1);
}
.line-clamp-2 {
  @include LineClamp(2);
}

//====== Profile STripe CSS Start ======

.stripeAccount-list {
  position: relative;
  display: flex;
  flex-direction: column;

  .stripeAccount {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 24px 0;
    border-bottom: 1px solid var(--border_color);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .iconInputGrp {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;

      .iconify {
        height: 23px;
        width: 54px;
      }

      .ai_input_container {
        width: 100%;
        max-width: 575px;
      }
    }
  }
}

//====== Profile STripe CSS End ======

.payment_mode_container {
  padding-left: 86px;
  padding-right: 86px;
  padding-top: 61px;
  padding-bottom: 61px;
}
.stripe_payment_card .payment_mode_container {
  padding: 10px;
}
