.alert-dismissible {
  .btn-close {
    left: 0;
    right: auto;
  }
}
.alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 3.75rem;
}
