// Bottom Bordered Nav Tabs
.tab-bottom-bordered {
  border-bottom: 4px solid rgba(var(--#{$variable-prefix}primary-rgb), 0.1);
  .nav-tabs {
    .nav-link {
      color: $body-color;
      position: relative;
      border: 0px;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        border-radius: $border-radius;
        background: var(--#{$variable-prefix}primary);
        bottom: -3px;
        left: 50%;
        top: unset;
        z-index: 1;
        transition: all 400ms ease;
      }
      &::before {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        border-radius: $border-radius;
        background: var(--#{$variable-prefix}primary);
        bottom: -3px;
        right: 50%;
        top: unset;
        z-index: 1;
        transition: all 400ms ease;
      }
      &.active {
        background-color: unset;
        color: var(--#{$variable-prefix}primary);
        box-shadow: unset;
        border: none;
        &::before {
          width: 60%;
          right: 0;
          transition: all 400ms ease;
        }
        &::after {
          width: 60%;
          left: 0;
          transition: all 400ms ease;
        }
      }
      &:hover {
        border: 0px;
      }
    }
  }
  &.iq-custom-tab-border {
    .nav-tabs {
      .nav-link {
        &:nth-child(1) {
          padding-left: unset;
        }
      }
    }
  }
}

// Icon With Nav Tab
.nav-iconly {
  .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    @if $enable-rounded == true {
      border-radius: 0.5rem;
    }
    padding: 1rem;
    background: rgba($secondary, 0.1);
    color: inherit;
  }
}
// Tab Animation
.tab-content {
  &.iq-tab-fade-up {
    .tab-pane.fade {
      opacity: 0;
      transform: translateY(10px);
      transition:
        opacity 300ms ease-in-out,
        transform 300ms ease-in-out;
      &.active.show {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
