.tab-scroll {
  position: relative;
}

.tab-shadow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 11;

  &.disabled {
    display: none !important;
  }

  @media (max-width: 767px) {
    width: 25px;
  }

  .arrow {
    position: relative;
    height: 10px;
    width: 10px;
    margin: 0;
    border-top: 3px solid var(--text_color);
    cursor: pointer;

    @media (max-width: 767px) {
      height: 8px;
      width: 8px;
    }
  }

  &.left {
    left: 0;
    padding-left: 6px;
    justify-content: start;
    border-radius: 12px 0 0 12px;
    background: linear-gradient(90deg, var(--primary_color), transparent);

    .arrow {
      border-top-left-radius: 4px;
      border-left: 3px solid var(--text_color);
      transform: rotate(-45deg);
    }
  }
  &.right {
    right: 0;
    padding-right: 6px;
    justify-content: end;
    border-radius: 0 12px 12px 0;
    background: linear-gradient(-90deg, var(--primary_color), transparent);

    .arrow {
      border-top-right-radius: 4px;
      border-right: 3px solid var(--text_color);
      transform: rotate(45deg);
    }
  }
}
