$value: 5;
@while $value <= 130 {
  .avatar-#{$value} {
    height: $value + px;
    width: $value + px;
    line-height: $value + px;
    object-fit: cover;
    border: "1px solid";
  }
  $value: $value + 5;
}
