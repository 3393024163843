// ====== Topic Card CSS Start ======

.homeTopicList {
  .topicCard {
    margin-bottom: 15px;
  }
}

.post_heading {
  font-weight: 700;
  color: var(--heading_color);
  text-transform: capitalize;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  @media (max-width: 767px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

.post_desc {
  font-weight: 400;
  color: var(--text_color);

  @media (min-width: 768px) {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  @media (max-width: 767px) {
    font-size: 11px !important;
    line-height: 16px !important;
  }
}

.post_heading_section {
  @media (min-width: 768px) {
    gap: 12px;
  }

  @media (max-width: 767px) {
    gap: 10px;
  }

  .avatar {
    @include AvatarCommon(40px, 40px, 30px);
  }

  .topic_name {
    color: var(--text_color);
    font-weight: 400;
    margin-bottom: 0px;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 14px;
    }

    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
    }
  }
}

.commentFilter-dropdown {
  .dropdown {
    .dropdown-toggle {
      color: var(--text_color);
      align-items: center;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}

// ====== Topic Card CSS End ======

// ====== Action Buttons Start ======

.action-btn-grp {
  gap: 5px;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  line-height: normal;
  padding: 6px 10px !important;
  gap: 5px;

  .iconWrap {
    display: flex;
    align-items: center;

    * {
      display: flex;
      align-items: center;
    }

    svg {
      height: 12px !important;
      width: 12px !important;
      color: var(--icon_color);
    }
  }

  p {
    font-size: 10px !important;
  }

  * {
    margin: 0 !important;
  }

  &.hover-action-button:hover,
  &.liked {
    .iconWrap {
      svg {
        color: var(--cta_color) !important;
      }
    }
  }

  &:disabled,
  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

@media (min-width: 768px) {
  .action-btn {
    padding: 8px 15px !important;
    gap: 8px;

    .iconWrap {
      svg {
        height: 16px !important;
        width: 16px !important;
      }
    }

    p {
      font-size: 14px !important;
    }
  }
}

// ====== Action Buttons End ======

// ====== Comment Box Start ======

.post_comments {
  .commentBox {
    position: relative;
    display: flex;
    align-items: flex-start;

    @media (min-width: 768px) {
      gap: 16px;
      margin-top: 15px;
    }

    @media (max-width: 767px) {
      gap: 10px;
      margin-top: 10px;
    }

    .commonAvatar {
      @include AvatarCommon(34px, 34px, 24px);

      .liveStatus {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          bottom: -2px;
          right: -2px;
        }

        svg {
          @media (min-width: 768px) {
            height: 10px;
            width: 10px;
          }

          @media (max-width: 767px) {
            height: 8px;
            width: 8px;
            bottom: -2px;
            right: -2px;
          }
        }

        * {
          display: flex;
        }
      }
    }
  }
}

// .create-comments {
//   &.filesSelected {
//     margin-bottom: -1px;
//     div.mentions__control,
//     div.comment-area {
//       textarea {
//         @media (min-width: 768px) {
//           border-radius: 20px 20px 0 0 !important;
//         }
//         @media (max-width: 767px) {
//           border-radius: 12px 12px 0 0 !important;
//         }
//       }
//     }
//   }
// }

.replied-comments {
  background-color: var(--primary_color);
  color: var(--text_color);
  border: 1px solid var(--text_color);

  @media (min-width: 768px) {
    padding: 10px 15px;
    border-radius: 0px 21px 21px 21px;

    h4 {
      font-size: 14px !important;
    }
  }

  @media (max-width: 767px) {
    padding: 12px;
    border-radius: 0px 12px 12px 12px;

    h4 {
      font-size: 12px !important;
    }
  }

  h4 {
    @media (min-width: 768px) {
      font-size: 14px !important;
    }

    @media (max-width: 767px) {
      font-size: 12px !important;
    }
  }

  p {
    @media (max-width: 767px) {
      font-size: 10px;
      font-weight: 500;
    }
  }

  .commentsMedia {
    img,
    video {
      border-radius: 6px;
    }
  }
}

.comment-actions {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 3px 0 3px 15px;
  margin-top: 8px;

  .actions-inner {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .actionsBtn {
    position: relative;
    border-radius: 4px;
    line-height: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    // padding-top: 3px;
    // padding-bottom: 3px;
    color: var(--text_color);

    @media (min-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 767px) {
      font-size: 12px;
    }

    &.isLiked {
      font-weight: bold;
    }
  }

  .ThumbsupIcon {
    svg {
      @media (max-width: 767px) {
        height: 10px;
        width: 10px;
      }
    }
  }

  .upDownIcon {
    svg {
      // @media (max-width: 767px) {
      height: 10px;
      width: 10px;
      // }
    }
  }

  .dotSeparator,
  .ThumbsupIcon {
    display: flex;
    align-items: center;
    line-height: 0;
  }
}

// ====== Comment Box End ======

// ====== Report Post CSS Start ======

.reportedUser {
  padding-top: 12px;
  padding-bottom: 12px;

  &.head {
    border-bottom: 1px solid var(--border_color);
  }

  &.foot {
    border-top: 1px solid var(--border_color);
  }

  img {
    border: 1px solid var(--text_color);
    border-radius: 50%;
    overflow: hidden;

    @media (min-width: 768px) {
      height: 30px;
      width: 30px;
    }

    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
    }
  }
}

// ====== Report Post CSS End ======

// ====== Post Comment Input Area CSS Start ======

.post_comment_section {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 15px;
  background: var(--primary_color);
  border-radius: 0 0 18px 18px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.comment-area-files {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: -19px;
  padding: 30px 12px 12px 12px;
  border: 1px solid var(--text_color);
  background-color: var(--primary_color);

  @media (min-width: 768px) {
    border-radius: 0 0 20px 20px;
  }

  @media (max-width: 767px) {
    border-radius: 0 0 12px 12px;
  }

  .comment-area-file-item {
    position: relative;
    display: block;
    width: 170px;
    height: 150px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 6px;
    }

    .files-list-item-remove {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: var(--primary_color);
      color: var(--text_color);
      padding: 4px;
      border-radius: 4px;
      transition: 0.2s all;

      &:hover {
        background-color: var(--text_color);

        .material-symbols-outlined {
          color: var(--primary_color);
        }
      }

      .material-symbols-outlined {
        transition: 0.2s all;
        font-size: 19px !important;
      }
    }
  }
}

// ====== Post Comment Input Area CSS End ======

// ====== Post Detail Page CSS Start ======

.topicDetail-page {
  .topicCard {
    margin: 20px 0;
  }

  .post_comment_section {
    @media (min-width: 992px) {
      bottom: 0;
    }

    @media (max-width: 991px) {
      bottom: 40px;
    }
  }
}

// ====== Post Detail Page CSS End ======

// ====== Post Doc Download Card CSS Start ======

.docDownload-wrap {
  position: relative;

  &.onGrid {
    display: grid;
  }

  @media (min-width: 768px) {
    gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 767px) {
    gap: 6px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.docDownload {
  position: relative;
  color: var(--text_color);
  line-height: normal;

  &.inBox {
    border: 1px solid var(--border_color);
    border-radius: 4px;

    @media (min-width: 768px) {
      padding: 6px 8px;
    }

    @media (max-width: 767px) {
      padding: 3px 6px;
    }
  }

  .downloadIcon {
    display: flex;
    align-items: center;

    svg {
      cursor: pointer;
      color: var(--text_color);
      border-radius: 50%;

      @media (min-width: 768px) {
        height: 20px;
        width: 20px;
      }

      @media (max-width: 767px) {
        height: 16px;
        width: 16px;
      }

      &:hover {
        background-color: var(--text_color);
        color: var(--background_color);
      }
    }
  }
}

// ====== Post Doc Download Card CSS End ======

.h-38 {
  height: 38px;
}

.user-post {
  .slick-slider {
    .slick-slide {
      padding: 0 !important;
    }

    .slick-next,
    .slick-prev {
      height: 35px !important;
      width: 35px !important;
      color: var(--text_color) !important;
      background-color: var(--background_color);
      min-width: 35px !important;
      border-radius: 50%;
      z-index: 1;

      &::before {
        content: " ";
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 2px;
        border: 0;
        border-style: solid;
        border-color: var(--text_color);
      }

      &:hover {
        background-color: var(--text_color);

        &::before {
          border-color: var(--background_color);
        }
      }
    }

    .slick-prev {
      left: 15px;

      &::before {
        left: calc(50% + 2px);
        border-left-width: 3px;
        border-bottom-width: 3px;
      }
    }

    .slick-next {
      right: 15px;

      &::before {
        left: calc(50% + -2px);
        border-right-width: 3px;
        border-top-width: 3px;
      }
    }
  }
}

nav.react-images__navigation button {
  margin: 0px !important;
  padding: 0px !important;
  animation-fill-mode: backwards;
  overflow: hidden;
}

nav.react-images__navigation button svg {
  height: 30px !important;
  width: 30px !important;
}

.move_topic_checkbox {
  color: var(--heading_color);
}

.move_topic_checkbox:hover {
  color: var(--text_color);
}

.share_modal_inner_container {
  max-height: 400px;
}

.dot-seperator {
  font-size: 14px;
  color: var(--text_color);
}

.topic_create_container_mobile {
  background-color: var(--primary_color);
}
