// === Categories Results Page CSS Start ===

.categoriesImage {
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon_color);
  border: 1px solid var(--border_color);
  span {
    line-height: 0;
  }
  &.active {
    color: var(--text_color);
    border: 1px solid var(--text_color);
  }
}

.categoriesList {
  .clearAllBtn {
    color: var(--text_color) !important;
    // &:disabled,
    // &.disabled {
    //   color: var(--icon_color) !important;
    // }
  }

  .form-check-label {
    color: var(--icon_color) !important;
    word-break: break-all;
    text-transform: capitalize;
  }

  .form-check {
    &:hover,
    &.active {
      .form-check-input {
        &[type="checkbox"] {
          border-color: var(--text_color) !important;
          &:checked {
            border-color: var(--text_color) !important;
          }
        }
      }
    }
    &.active {
      label {
        color: var(--text_color) !important;
      }
    }
  }

  .form-check-input {
    // margin-top: 2px !important;
    // background-color: transparent !important;

    &[type="checkbox"] {
      &:checked {
        // background-color: var(--text_color);
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
        border-color: var(--text_color) !important;

        & ~ label {
          color: var(--text_color) !important;
          .categoriesImage {
            color: var(--text_color) !important;
            border-color: var(--text_color) !important;
          }
        }
      }
    }
  }
}

// === Categories Results Page CSS End ===
