.project-basicDetail {
  position: relative;
  display: flex;

  @media (min-width: 768px) {
    padding: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }

  .titleDetail-wrap {
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
      gap: 20px;
    }
    @media (max-width: 767px) {
      position: relative;
      padding: 12px;
      gap: 12px;
    }
  }

  .titleDetail {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      margin-bottom: 0;
      color: var(--text_color);
      line-height: normal;
    }

    .detailTitle {
      color: var(--heading_color);
      font-weight: 700;
      text-transform: capitalize;
      line-height: normal;

      @media (min-width: 768px) {
        font-size: 22px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }

      // .textDotted {
      //   max-width: 200px;
      //   text-overflow: ellipsis;
      //   overflow: hidden;
      // }
    }

    .detailTagline {
      font-weight: 400;
      @media (min-width: 768px) {
        font-size: 12px;
      }
      @media (max-width: 767px) {
        font-size: 11px;
      }
    }

    .detailMemberAndType {
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 14px;
        margin-top: 7px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
        margin-top: 3px;

        .categoriesImage {
          height: 18px;
          width: 18px;
          min-width: 18px;
        }
      }
    }
  }

  .project_confirm {
    display: flex;
    align-items: center;
    gap: 15px;
    min-width: 150px;

    @media (min-width: 768px) {
      justify-content: end;
    }
    @media (max-width: 767px) {
      justify-content: space-between;
      border-top: 1px solid var(--border_color);
      padding: 12px;
    }

    .paidPrice {
      line-height: normal;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        order: 2;
      }
    }

    .confirm_btn {
      position: relative;
      display: flex;
      gap: 10px;

      @media (min-width: 768px) {
        align-items: end;
        flex-direction: column;
      }
      @media (max-width: 767px) {
        order: 1;
      }

      .btn.btn-primary {
        min-width: 75px;
      }
    }
  }
}

.communityType {
  position: absolute;
  bottom: 2px;
  right: 15px;
  text-transform: capitalize;
}

.projectImg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--background_color);
  border: 1px solid var(--border_color);

  @media (min-width: 768px) {
    height: 86px;
    width: 86px;
    min-width: 86px;
  }
  @media (max-width: 767px) {
    height: 86px;
    width: 86px;
    min-width: 86px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  .files-dropzone-list {
    img {
      width: 28px !important;
      height: 28px !important;
    }
  }
}

//====== Create Project CSS Start ======

.projectType-checkList {
  .radio_label {
    margin-top: 2px;
  }
}

.createProject-terms {
  .form-check-input[type="checkbox"] {
    margin-top: 0;
  }
}

.create_resource_button {
  @media (max-width: 1027px) {
    margin-top: 18px;
  }
}

.createProject-form {
  position: relative;

  .withInput-bg {
    .custom_text_input,
    .custom_textarea_input,
    .PhoneInput,
    .select__control,
    .PhoneInputInput {
      background: var(--primary_color) !important;
    }
  }
}

.faq_edit {
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;
  }

  textarea {
    height: 150px;
  }
}

.project-basicDetail .dropdown-menu {
  min-width: 7rem;
}

.stripeSec {
  position: relative;

  .stripeIcon {
    position: relative;
    height: 28px;
    width: 53px;
  }

  .sstContent {
    p {
      font-size: 12px;
      margin-bottom: 0px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .on_board_button {
    p {
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .smsShareIcon {
    font-size: 30px;
  }
}

.AccountRefresh {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  text-decoration: underline;

  .refreshIcon {
    font-size: 24px;
  }
}

.stripe_acc_dropdown .form-group {
  width: 250px;
  margin: 0;
}

.stripeMsg {
  font-size: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  text-transform: capitalize;
  white-space: nowrap;

  .iconify {
    font-size: 22px;
  }
}

//====== Create Project CSS End ======

//====== Project Setting CSS Start ======

.themeCard {
  position: relative;
  display: block;
  width: 110px;
  padding: 2px 2px 0 2px;
  background: #e3e3e3;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 2px solid var(--primary_color);
  cursor: pointer;

  &.active {
    border-color: var(--border_color);
  }

  .themeCard-inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    background: #d8cfd0;
    height: 60px;
    border-radius: 8px;
  }

  .themeCard-palette {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px;
    width: 100%;

    .dot {
      position: relative;
      display: block;
      height: 13px;
      width: 13px;
      border-radius: 50%;
      border: 1px solid #e3e3e3;
      background: red;
    }
  }

  .themeCard-name {
    font-size: 14px;
    margin: 5px 0;
    color: #212121;
  }
}

//====== Project Setting CSS End ======

//====== Project Payment CSS Start ======

.paymentStatus {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .statusIcon {
    margin-bottom: 20px;

    &.success {
      color: #05ab0b;
    }
    &.warn {
      color: #ff0000;
    }

    @media (min-width: 768px) {
      height: 66px;
      width: 66px;
    }
    @media (max-width: 767px) {
      height: 44px;
      width: 44px;
    }
  }

  .statusTitle {
    @media (min-width: 768px) {
      font-size: 24px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .statusDesc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 375px;
    margin: 12px 0 20px 0;
    p {
      margin: 0;
      @media (min-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

//====== Project Payment CSS End ======
