@media (max-width: 992px) {
  .right_members .svg_button_icon_small,
  .svg_button_icon_without_circle_selected,
  .svg_button_icon_without_circle,
  .Delete_icon {
    height: 18px !important;
    width: 18px !important;
  }
  .common_svg_icon,
  .svg_button_icon {
    height: 28px;
    width: 28px;
  }

  .svg_button_icon_home {
    height: 28px;
    width: 28px;
  }
}
@media (max-width: 768px) {
}

@media (max-width: 600px) {
  .svg_button_icon_without_circle_selected,
  .svg_button_icon_without_circle,
  .Delete_icon {
    height: 16px !important;
    width: 16px !important;
  }
  .common_svg_icon,
  .svg_button_icon {
    height: 24px;
    width: 24px;
  }

  .svg_button_icon_home {
    height: 24px;
    width: 24px;
  }
  .svg_button_icon_ai {
    height: 14px;
    width: 14px;
  }
  .svg_button_icon_ai_active {
    height: 14px;
    width: 14px;
  }
  .svg_button_icon_ai_refresh {
    height: 14px;
    width: 14px;
  }
}
@media (max-width: 480px) {
}
@media (max-width: 380px) {
  .common_svg_icon,
  .svg_button_icon {
    height: 22px;
    width: 22px;
  }

  .svg_button_icon_home {
    height: 22px;
    width: 22px;
  }
}
@media (max-width: 330px) {
}
