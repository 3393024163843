.resourceBanner-wrap {
  position: relative;
}

.resourceBanner {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 340px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 300px;
  }
  @media (max-width: 767px) {
    height: 240px;
  }

  img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.bannerContent {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  gap: 12px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
  border-radius: 0 0 12px 12px;

  @media (min-width: 768px) {
    padding: 30px 25px 15px 25px;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    padding: 30px 12px 12px 12px;
    flex-direction: column;
  }

  .title {
    position: relative;
    font-weight: 700;
    line-height: normal;
    color: #ffffff !important;

    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .price-grp {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;

    @media (min-width: 768px) {
      justify-content: flex-end;
    }

    .price {
      font-weight: 700;
      line-height: normal;
      color: #ffffff !important;

      @media (min-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 767px) {
        font-size: 14px;
        order: 2;
        margin-left: auto;
      }
    }
  }
}

.overview_res {
  position: relative;
  .dec {
    p {
      line-height: normal;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        font-size: 14px;
      }
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}

.modules_res {
  .moduleBox-list {
    .moduleBox {
      position: relative;
      display: flex;
      align-items: center;
      background: var(--primary_color);
      border-radius: 4px;
      font-weight: bold;
      transition: 0.2s all;
      padding: 8px 14px;
      min-height: 46px;
      color: var(--text_color);
      border: 1px solid var(--border_color);

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      @media (min-width: 768px) {
        gap: 10px;
        font-size: 16px;
      }
      @media (max-width: 767px) {
        gap: 8px;
        font-size: 12px;
      }

      .svgIcon {
        line-height: 0;
        height: 20px;
        width: 20px;
        fill: var(--text_color);
      }

      &:hover {
        color: var(--text_color);
      }
    }
  }
}

.moduleTitle {
  position: relative;
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.modulePdf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border_color);
  background-color: var(--primary_color);
  border-radius: 4px;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  .pdfIcon {
    height: 18px;
    width: 18px;
  }

  .fileName {
    max-width: 200px;
  }
}

.moduleImage {
  position: relative;
  height: 360px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;

  img {
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.moduleVideo {
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;

  iframe {
    height: 360px;
    width: 100%;
  }

  #player {
    width: 100%;
  }
}

.add_more {
  color: var(--text_color);
  font-family: Arimo;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}
.publish_contnet {
  margin-left: 33px;
  margin-top: 26px;
  margin-right: 19px;
}
.resource_banner {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  height: 100%;
  object-fit: cover;
}

.card_module {
  background-color: var(--primary_color);
}

.active_bread {
  color: var(--text_color);
  font-weight: 700;
}

.in_active_bread {
  color: var(--icon_color);
  font-weight: 700;
}

.base_dark:hover .module_checkbox {
  border: 1px solid var(--cta_color);
}

.progress {
  height: 5px;
}

.notification_content h6 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
}

.module_progress_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
}
.upload_image_button {
  border: 1px solid var(--border_color);
  padding: 7px 12px;
  background-color: var(--background_color);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
.upload_image_button_module {
  border: 1px solid var(--border_color);
  padding: 7px 12px;
  background-color: var(--background_color);
  border-radius: 4px;
  // @media (min-width: 767px) {
  //   max-width: 100px;
  // }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
  display: flex;
  justify-content: center;
}
.resource_language {
  text-transform: capitalize;
}
.spinner-grow {
  height: 10px !important;
  width: 10px !important;
}
.clear_all_category {
  margin-left: 8px;
}

.secretkey_input_container {
  width: 70%;
}
.empty_profile_image_container {
  display: flex;
  justify-content: center;
}
.empty_profile_image {
  max-width: 500px;
  height: 100%;
}

.stripe_payment_card {
  padding: 10px 10px 10px 10px;
}

.modal_add_payment_method {
  border: none !important;
}

.payment_mode_container .custom_text_input {
  padding-right: 50px;
}

.email_template_preview {
  border: 2px solid #d8cfd0;
}
