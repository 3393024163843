.btn {
  &.btn-border {
    @include button-outline-variant($primary);
    color: $secondary;
    border: $border-width * 2 solid $border-color;
    padding: $spacer * 0.5 $spacer;
    &:hover {
      background: transparent;
      border-color: var(--#{$variable-prefix}primary);
      color: var(--#{$variable-prefix}primary);
    }
    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active {
      background: var(--#{$variable-prefix}primary);
      color: var(--#{$variable-prefix}white);
      border-color: var(--#{$variable-prefix}primary);
      &.bg-transparent {
        color: var(--#{$variable-prefix}primary);
      }
    }
  }
}
