.product {
  .offer {
    height: 3.125rem;
    width: 3.125rem;
    line-height: 3.125rem;
    @if $enable-rounded == true {
      @include radius-mixin($border-radius-pill);
    }
    font-size: $font-size-base;
    font-weight: 700;
    text-align: center;
    color: $dark;
    background-color: $primary_color;
    @if $enable-shadows == true {
      @include shadow-mixin(0px 0px 20px 0px ($primary, 0.1));
    }
    // box-shadow: 0px 0px 20px 0px ($primary,0.1);
    position: absolute;
    top: 0.625rem;
    left: 0.625rem;
  }
  span.categry {
    &:before {
      content: "";
      height: 0.5rem;
      width: 0.5rem;
      background-color: $primary_color;
      border: 2px solid $primary;
      position: absolute;
      left: 0;
      top: 0.313rem;
      @if $enable-rounded == true {
        @include radius-mixin($border-radius-pill);
      }
    }
  }
  .price {
    position: absolute;
    right: -0.5rem;
    bottom: 0.938rem;
    padding: 0.125rem 0.625rem;
    display: inline-block;
    color: $white;
    background: $primary;
    @if $enable-shadows == true {
      @include shadow-mixin(0px 0px 20px 0px rgba(44, 101, 144, 0.1));
    }
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0px 0px 8px;
      border-color: transparent transparent transparent $primary;
      position: absolute;
      left: auto;
      right: 0;
      top: -0.5rem;
      margin: 0 auto;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0px 0px 8px 8px;
      border-color: transparent transparent transparent $primary;
      position: absolute;
      left: auto;
      right: 0;
      bottom: -0.5rem;
      margin: 0 auto;
    }
  }
  .regular-price {
    text-decoration: line-through;
    font-size: $font-size-base;
  }
}
#quantity {
  display: inline-block;
  width: 1.875rem;
  height: 1.75rem;
  border: 1px solid $border-color;
  text-align: center;
}
button.qty-btn {
  border: 1px solid $border-color;
  background-color: $primary_color;
  height: 1.75rem;
  width: 1.875rem;
  vertical-align: top;
}
.form-add-btn {
  margin-bottom: 1.25rem;
}
