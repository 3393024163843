@import "../../../../variables";
.smallbutton {
  font-size: 10px;
  border-radius: 20px;
}
.nav-tabs {
  .nav-item {
    a {
      color: #324253;
      border: none;
      border-bottom: 2px solid transparent;
    }
  }
}
.borderbox {
  width: 100%;
}
.borderbox1 {
  width: 100%;
}
.hr-vertical {
  width: 1px;
  height: auto !important;
  margin: 0 0.5rem;
}
.shadowbg {
  padding: 11px 34px 11px;
}
.shadowbg:hover {
  color: $primary;
  background-color: $card_color;
}
.containertop {
  margin-top: -3rem;
}
.cursor-pointer {
  cursor: pointer;
}
.active-tag {
  background-color: $cta_background !important;
}
.inactive-tag {
}
.active-filter {
  background-color: #a6a6ff !important;
}
.inactive-filter {
}
.pre-wrap {
  white-space: pre-wrap;
}
.border-none {
  border-radius: 0 !important;
  border: none !important;
}
.search-icon-new {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
  display: flex;
  align-items: center;
}
.fixed-height {
  height: 200px;
  overflow: auto;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
.width-100 {
  width: 100%;
}
.text-wrap {
  white-space: normal;
}
.height-100 {
  height: 100px;
  overflow: auto;
}
.pill {
  border-radius: 50rem !important;
}
.liked {
  padding: 4px;
  background-color: var(--text_color) !important;
  border-radius: 20px;
  color: var(--cta_color) !important;

  svg {
    fill: var(--text_color) !important;
  }
}
.mentions__mention {
  background-color: blue;
}
//Responsive
@media (min-width: 320px) and (max-width: 991.98px) {
  .shadowbg {
    text-align: center;
  }
}
@media (min-width: 700px) and (max-width: 1500px) {
  .borderbox1 {
    display: flex;
  }
}
//profile3
.profilesearch {
  border-radius: 24px;
}
.profile-header {
  .user-detail1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 14rem;
    bottom: auto;
  }
}
@media (min-width: 300px) and (max-width: 758px) {
  .profile-header {
    .user-detail1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 6rem;
      bottom: auto;
    }
  }
}
@media (min-width: 768px) and (max-width: 1020px) {
  .profile-header {
    .user-detail1 {
      position: absolute;
      left: 0;
      right: 0;
      top: 10rem;
      bottom: auto;
    }
  }
}
// @media (max-width: 991.98px){
// .profile-header .user-detail1{
//     position: relative;
//     bottom: 0;
//     margin-top: -3.125rem;
// }
// }
// @media (min-width: 1024px) and (max-width: 1100px) {
//     .profile-header .user-detail1{
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: auto;
//         bottom: 60rem;
//     }
// }
@media (max-width: 575.98px) {
  .editcover {
    display: none;
  }
}
