.modal-content {
  background: $card_color-bg;

  .modal-header {
    border-color: $border-color;
  }
  .modal-footer {
    border-top: 1px solid $border-color;
  }
}
