.feather-icon {
  a {
    color: $secondary;
    font-weight: 300;
    &:hover {
      color: $primary;
    }
  }
}
.message-icon {
  a {
    color: $secondary;
    font-weight: 300;
    &:hover {
      color: $primary;
    }
  }
}
