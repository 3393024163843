// Main
@import "../../../variables";
$body-bg: #0a081b;
$body-color: $cta_background;
$card_color-bg: $primary_color;
$border-color: $cta_background;
$dark: #0a081b;
$theme-colors: map-merge(
  $theme-colors,
  (
    "dark": $dark
  )
);
$alert-bg-scale: 60%;
$alert-border-scale: 80%;
$alert-color-scale: 80%;
