@import "../../../src/assets/variables";

// .custom_text_input {
//   background-color: $card_color;
// }

.control-label,
.form-label,
label {
  color: var(--text_color);
}

.not-allowed {
  cursor: not-allowed;
}

.custom_text_input,
.custom_textarea_input,
.PhoneInput,
.select__control,
.PhoneInputInput {
  border-radius: 4px;
  background: var(--background_color) !important;
  border: 1px solid var(--border_color);
  color: var(--text_color) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;

  &::placeholder {
    color: var(--text_color) !important;
    opacity: 0.6;
  }
}

.AuthPage {
  .custom_text_input,
  .custom_textarea_input,
  .PhoneInput,
  .select__control,
  .PhoneInputInput {
    background: var(--text_color) !important;
    border: 1px solid var(--text_color);
    color: var(--background_color) !important;

    &::placeholder {
      color: var(--background_color) !important;
      opacity: 0.6;
    }
  }
}

input[type="date"] {
  appearance: none;
  -webkit-appearance: none;
}

.custom_textarea_input {
  padding: 0.375rem 0.75rem;
}

.PhoneInputInput {
  border: none !important;
  //background-color: $cta_background;
}

.form-control {
  &[readonly],
  &:disabled,
  &.disabled {
    opacity: 1 !important;
    cursor: not-allowed;
    background: var(--background_color);
  }
}

// .bg-color-box-textarea {
//   margin: 20px 0 !important ;
// }

.bg-color-box-textarea textarea {
  padding: 6px 12px !important;
}
.custom_box_search_root {
  margin-bottom: 0px !important;
}

// .custom_text_input:not(:placeholder-shown),
// .custom_textarea_input:not(:placeholder-shown) {
//   background-color: $cta_background;
//   // border: 1px solid $cta_background;
//   /* Styles when the input field is filled */
// }

// .custom_text_input:focus,
// .custom_textarea_input:focus {
//   background-color: $cta_background;
//   /* Styles when the input field is focused */
// }

// .PhoneInputInput:focus-within {
//   background-color: $cta_background !important;
// }

// //.PhoneInputInput:not(:focus-within) {
// .PhoneInputInput:not(:focus-within):not(:empty),
// .PhoneInputInput:not(:focus):not(:empty),
// .PhoneInputInput.notEmpty:focus-within {
//   background-color: $cta_background !important;
// }

// .PhoneInputInput:not(:empty) {
//   background-color: $cta_background !important;
// }

// .PhoneInputInput:not(:placeholder-shown):focus-within {
//   background-color: #e3e3e3 !important;
// }

// .PhoneInputInput:not(:placeholder-shown) {
//   background-color: #e3e3e3 !important;
// }
// .PhoneInputInput.notEmpty:focus-within {
//   background-color: #e3e3e3 !important;
// }

// .PhoneInput:focus-within {
//   background-color: $cta_background !important;
// }

// input[type="text"]:valid {
//   /* Styles when the input field contains a valid value */
// }

.light.Custom_dropdown_New {
  border-radius: 8px !important;
  border: 1px solid var(--border_color);
  background-color: $cta_background;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 4px !important;
  height: 36px;
  color: $font_color !important;
  padding: 0px 12px 0px 12px;
}

.light.Custom_dropdown_New option {
  border: 1px solid var(--border_color);
  background-color: $card_color;
}

.light.Custom_dropdown_New option.selected,
.light.Custom_dropdown_New option:checked {
  background-color: $cta_background;
}

.input-error-msg {
  color: #ff4848;
}

.input-success-msg {
  color: #4caf50;
}

.modal-dialog.popup-modal.popup-bottom {
  max-width: 100% !important;
  text-align: left;
}

.form-control {
  &::placeholder {
    color: var(--text_color) !important;
    opacity: 0.6;
  }
  &:focus {
    background: var(--background_color);
    color: var(--text_color);
  }
}
