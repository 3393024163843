.admin-chart-02 {
  height: 190px !important;
}
.admin-chart-03 {
  height: 208px !important;
}
.admin-chart-07 {
  min-height: 208px !important;
}
.admin-chart-06 {
  height: 200px !important;
}
