.iq-media-group {
  .iq-media:first-child {
    margin-right: 0;
    margin-left: unset;
  }
}

.iq-media-group {
  .iq-media {
    margin-right: -1.25rem;
    margin-left: unset;
  }
}
