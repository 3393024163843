.group-info {
  h4 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .info {
    h4 {
      text-align: right;
    }
  }
}
.group-details {
  ul {
    li {
      p {
        text-align: center;
      }
      h6 {
        text-align: center;
      }
    }
  }
}
