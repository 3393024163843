// ====== Common Root Variables Start ======

:root {
  --primary_color: #220941;
  --cta_color: #025bde;
  --background_color: #100227;
  --card_color: #220941;
  --icon_color: #9073c1;
  --border_color: #9073c1;
  --heading_color: #ffffff;
  --text_color: #ffffff;
}

// ====== Common Root Variables End ======

// ====== Common Root Variables End ======

$primary_color: #220941 !default;
$cta_background: #ffffff !default;
$font_color: #ffffff !default;
$card_color: #220941 !default;
$inactive_icon: #9073c1;
$green_color: #4caf50;

$primary_filter_color: brightness(0) saturate(100%) invert(24%) sepia(1%) saturate(513%)
  hue-rotate(300deg) brightness(93%) contrast(92%) !important;
$white_filter_color: invert(100%) sepia(100%) saturate(0%) hue-rotate(220deg) brightness(107%)
  contrast(107%);

// ====== Common Root Variables End ======

// ====== Common Mixin CSS Start ======

@mixin AvatarCommon($DesktopSize, $TabletSize, $MobileSize) {
  position: relative;
  border-radius: 50%;
  border: 1px solid var(--text_color);
  object-fit: cover;
  background-color: var(--primary_color);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1200px) {
    height: $DesktopSize;
    width: $DesktopSize;
    min-width: $DesktopSize;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    height: $TabletSize;
    width: $TabletSize;
    min-width: $TabletSize;
  }

  @media (max-width: 767px) {
    height: $MobileSize;
    width: $MobileSize;
    min-width: $MobileSize;
  }

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
}
@mixin LineClamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

// ====== Common Mixin CSS End ======
