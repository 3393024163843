@import "variables";

.svg_button_icon_without_circle path {
  fill: var(--icon_color);
}
.hover-action-button:hover .svg_button_icon_without_circle path {
  fill: var(--background_color);
  stroke: var(--background_color);
}

.svg_button_icon path:first-child {
  /* CSS styles for the first child div */
  fill: var(--background_color);
}
.svg_button_icon path:not(:first-child) {
  /* CSS styles for subsequent child divs */
  fill: var(--text_color);
}

.svg_button_icon_without_circle_selected path {
  fill: var(--background_color);
}

.selected_svg path:first-child {
  fill: var(--text_color);
}

.selected_svg path:not(:first-child) {
  fill: var(--background_color);
}
.ReactSvgImage_without_hover path:first-child {
  fill: var(--text_color);
}
.ReactSvgImage_without_hover circle {
  fill: none;
}
.ReactSvgImage_without_hover {
  height: 28px;
  width: 28px;
}

.common_svg_icon,
.svg_button_icon {
  height: 32px;
  width: 32px;
}
.svg_button_icon ellipse {
  fill: var(--icon_color);
}

.svg_button_icon:hover circle {
  fill: var(--icon_color) !important;
}
.svg_button_icon:hover ellipse {
  fill: var(--cta_color) !important;
}

.svg_button_action_icon path {
  stroke: var(--font_color) !important;
}

.primary-select a.dropdown-item:hover .svg_button_action_icon path {
  stroke: var(--cta_color) !important;
}

.svg_button_icon:hover path:first-child {
  /* CSS styles for the first child div */
  fill: var(--icon_color) !important;
  // stroke: var(--icon_color);;
}

.svg_button_icon:hover path:not(:first-child) {
  /* CSS styles for subsequent child divs */
  fill: var(--cta_color) !important;
}
.svg_button_icon_small {
  height: 18px;
  width: 18px;
}
.homeLeftPanel .svg_button_icon_small path:not(:first-child) {
  fill: var(--cta_color);
}
.right_members .svg_button_icon_small path:first-child {
  fill: none;
}
.right_members .svg_button_icon_small {
  height: 28px !important;
  width: 28px !important;
}

.svg_button_icon_without_circle {
  height: 18px;
  width: 18px;
}

.Download_icon {
  height: 22px;
  width: 22px;
}
.Delete_icon {
  height: 22px;
  width: 22px;
}

.svg_button_icon_ai {
  height: 22px;
  width: 22px;
}
.svg_button_icon_ai_active {
  height: 22px;
  width: 22px;
}
// .svg_button_icon_lock_profile {
//   height: 32px;
//   width: 32px;
// }
.svg_button_icon_ai_refresh {
  height: 20px;
  width: 20px;
}
.svg_button_icon_ai_active path:first-child {
  fill: var(--font_color);
}
.svg_button_icon_ai path:first-child {
  fill: var(--cta_color);
}
.svg_button_icon_ai_refresh path:first-child {
  stroke: var(--font_color);
}

div.card div.card-body.bg-darkblackcolor:hover > .dropdown-item h6 {
  color: var(--font_color);
}
// .svg_button_icon_lock_profile path:first-child {
//   fill: none;
//   stroke: var(--font_color);
// }
// .svg_button_icon_lock_profile path:not(:first-child) {
//   fill: var(--font_color);
// }

.svg_threedot_member_icon path:first-child {
  height: 13px;
  fill: var(--text_color);
}
.dropdown_icon_svg path:first-child {
  fill: var(--cta_color);
  stroke: var(--font_color);
}
.dropdown_icon_svg path:not(:first-child) {
  fill: var(--font_color);
  stroke: var(--font_color);
}
.dropdown-menu:hover .dropdown_icon_svg path:first-child {
  fill: var(--cta_color);
  stroke: var(--font_color);
}
.dropdown-menu:hover .dropdown_icon_svg path:not(:first-child) {
  fill: var(--font_color);
  stroke: var(--font_color);
}

// .resource_icon {
//   fill: var(--text_color);
// }

.resource_icon path:first-child {
  fill: var(--text_color);
}

.resource_icon path:not(:first-child) {
  fill: var(--text_color);
}

.svg_button_icon_delete path:first-child {
  fill: var(--text_color);
}

.svg_button_icon_delete path:not(:first-child) {
  fill: var(--text_color);
}

.svg_button_icon_delete circle {
  fill: var(--background_color);
}
.svg_button_icon_delete {
  height: 32px;
  width: 32px;
}

.svg_button_icon_delete:hover circle {
  fill: var(--inactive_icon) !important;
}
.svg_button_icon_delete:hover path:first-child {
  fill: var(--background_color);
}
.svg_button_icon_delete:hover path:not(:first-child) {
  fill: var(--background_color);
}

.module_next_icon,
.module_next_icon_disable,
.module_done_icon {
  @media (min-width: 767px) {
    height: 24px;
    width: 24px;
  }
  @media (max-width: 768px) {
    height: 20px;
    width: 20px;
  }
}

.module_next_icon path:first-child {
  fill: var(--text_color);
}

.module_next_icon path:not(:first-child) {
  fill: var(--text_color);
}

.module_next_icon_disable path:first-child {
  fill: var(--icon_color);
}

.module_next_icon_disable path:not(:first-child) {
  fill: var(--icon_color);
}

.module_done_icon path:first-child {
  fill: var(--icon_color);
}

.svg_sidebar_icon path:first-child {
  fill: var(--text_color);
}

.svg_sidebar_icon path:not(:first-child) {
  fill: var(--text_color);
}

.svg_sidebar_icon_2 path:first-child {
  fill: var(--text_color);
}

.svg_sidebar_icon_2 path:not(:first-child) {
  fill: var(--text_color);
}

.svg_sidebar_icon_selected path:first-child {
  fill: var(--icon_color);
}

.svg_sidebar_icon_selected path:not(:first-child) {
  fill: var(--icon_color);
}

.sidebar-menu-ul:hover .svg_sidebar_icon path:first-child {
  fill: var(--icon_color);
}

.sidebar-menu-ul:hover .svg_sidebar_icon path:not(:first-child) {
  fill: var(--icon_color);
}
