.notificationPage {
  margin-top: 30px;

  &.container-fluid {
    @media (min-width: 1200px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .delAllIcon {
    @media (max-width: 767px) {
      height: 24px;
      width: 24px;
    }
  }

  .delSingleIcon {
    circle {
      fill: var(--background_color);
    }

    &:hover {
      color: var(--background_color);

      circle {
        fill: var(--text_color);
      }
    }

    @media (min-width: 768px) {
      height: 30px;
      width: 30px;
    }

    @media (max-width: 767px) {
      height: 24px;
      width: 24px;
    }
  }
}

.notification-list {
  border-bottom: 1px solid var(--border_color);
  min-height: 64px;

  &.isNotRead {
    background-color: var(--background_color);
  }

  .notification-title-content {
    a {
      font-weight: 700;
      color: currentColor !important;
    }

    p {
      @include LineClamp(2);
    }
  }

  &:hover {
    background-color: var(--text_color);

    .notification-title-content h6,
    p,
    .material-symbols-outlined {
      color: var(--background_color) !important;
    }

    // .form-check {
    //   .form-check-input[type="checkbox"] {
    //     filter: invert(1);
    //   }
    // }
  }
}

// Footer Notification CSS

.footer-NotifyBadge {
  position: absolute;
  font-size: 8px;
  font-weight: 700;
  padding: 4px;
  line-height: 8px;
  right: 0;
  top: 0;
}

// Footer Notification CSS
