// === Search Results Page CSS Start ===

.searchResults {
  .card {
    .card-header {
      // background-color: var(--primary_color) !important;
      // border-bottom: 2px solid var(--border_color);
      h1 {
        font-size: 20px !important;
      }
    }
  }
}

.borderTitle {
  position: relative;

  h2,
  h3 {
    position: relative;
    font-weight: 700;
    background-color: var(--primary_color);
    padding-right: 30px;
    z-index: 1;
    display: inline-block;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  &.text-center {
    h2,
    h3 {
      padding-left: 30px;
    }
  }
  .backBorder {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    height: 1px;
    background-color: var(--border_color);
  }
}

.noResultFound {
  position: relative;
  padding: 10px 10px;
  p {
    font-size: 24px !important;
    color: var(--icon_color);
    margin: 0;
  }
}
.membersProfile {
  .MuiAvatar-root {
    height: 50px;
    width: 50px;
  }
}

// === Search Results Page CSS End ===
