#top-tab-list {
  li {
    a {
      i {
        margin-left: 0.938rem;
        margin-right: unset;
      }
    }
  }
}
#top-tabbar-vertical {
  li {
    a {
      i {
        margin: 0 0 0 1.25rem;
      }
    }
  }
}
