@font-face {
  font-family: "Arimo";
  src: url("Arimo-SemiBoldItalic.eot");
  src:
    url("Arimo-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Arimo-SemiBoldItalic.woff2") format("woff2"),
    url("Arimo-SemiBoldItalic.woff") format("woff"),
    url("Arimo-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-SemiBold.eot");
  src:
    url("Arimo-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("Arimo-SemiBold.woff2") format("woff2"),
    url("Arimo-SemiBold.woff") format("woff"),
    url("Arimo-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-Regular.eot");
  src:
    url("Arimo-Regular.eot?#iefix") format("embedded-opentype"),
    url("Arimo-Regular.woff2") format("woff2"),
    url("Arimo-Regular.woff") format("woff"),
    url("Arimo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-Italic.eot");
  src:
    url("Arimo-Italic.eot?#iefix") format("embedded-opentype"),
    url("Arimo-Italic.woff2") format("woff2"),
    url("Arimo-Italic.woff") format("woff"),
    url("Arimo-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-BoldItalic.eot");
  src:
    url("Arimo-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("Arimo-BoldItalic.woff2") format("woff2"),
    url("Arimo-BoldItalic.woff") format("woff"),
    url("Arimo-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-Medium.eot");
  src:
    url("Arimo-Medium.eot?#iefix") format("embedded-opentype"),
    url("Arimo-Medium.woff2") format("woff2"),
    url("Arimo-Medium.woff") format("woff"),
    url("Arimo-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-Bold.eot");
  src:
    url("Arimo-Bold.eot?#iefix") format("embedded-opentype"),
    url("Arimo-Bold.woff2") format("woff2"),
    url("Arimo-Bold.woff") format("woff"),
    url("Arimo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arimo";
  src: url("Arimo-MediumItalic.eot");
  src:
    url("Arimo-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("Arimo-MediumItalic.woff2") format("woff2"),
    url("Arimo-MediumItalic.woff") format("woff"),
    url("Arimo-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
