.accordion-item {
  background-color: $primary_color;
  border: 0;
  @if $enable-rounded == true {
    @include radius-mixin($border-radius);
  }
  &:not(:first-of-type) {
    border: 0;
  }
  .accordion-button {
    background-color: $primary_color;
  }
}
