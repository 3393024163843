.iq-todo-page {
  .search-link {
    position: absolute;
    top: 6px;
    left: 0.625rem;
    font-size: 1.125rem;
    line-height: 2.688rem;
    color: $body-color;
  }
  .todo-search {
    padding-left: 2.188rem;
  }
  ul {
    list-style: none;
    li {
      a {
        color: $body-color;
        text-decoration: none;
      }
    }
    ul {
      li {
        a {
          padding-left: 1.563rem;
          margin-bottom: 0.5rem;
        }
      }
    }
    > li {
      > a {
        padding: 0.625rem 0.938rem;
        display: block;
        @if $enable-rounded == true {
          @include radius-mixin($border-radius);
        }
      }
      li {
        &:hover {
          a {
            color: $primary;
            background: rgba($primary, 0.2);
          }
        }
      }
      li.active {
        a {
          color: $primary;
          background: rgba($primary, 0.2);
        }
      }
    }
    > li[aria-expanded="true"] {
      > a {
        color: $primary;
        background: rgba($primary, 0.2);
      }
    }
  }
}
.iq-todo-right {
  .search-link {
    position: absolute;
    top: -3px;
    left: 0.625rem;
    font-size: 1.125rem;
    line-height: 3.6rem;
    color: $body-color;
  }
  .todo-search {
    padding-left: 2.188rem;
  }
}
.add-new-project {
  > a {
    padding: 0 0.938rem;
    color: $body-color;
    text-transform: capitalize;
    text-decoration: none;
  }
}
.notification-icon {
  > span {
    height: 13px;
    width: 13px;
    @if $enable-rounded == true {
      @include radius-mixin($border-radius-pill);
    }
    line-height: 0.938rem;
    font-size: 0.5rem;
    text-align: center;
    position: absolute;
    top: -0.313rem;
    left: auto;
    right: -0.5rem;
  }
}
.todo-date {
  font-size: 1rem;
}
ul.todo-task-lists {
  li {
    &:hover {
      background: rgba($primary, 0.2);
      @if $enable-transitions == true {
        @include transition-mixin(all 0.3s ease-in-out);
      }
    }
  }
  li.active-task {
    background: rgba($primary, 0.2);
  }
}
.media-support-info {
  p {
    line-height: normal;
  }
}
.todo-task-list {
  .sub-task {
    display: none;
  }
  .sub-task.show {
    display: block;
  }
}
