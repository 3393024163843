.createPost-descTextarea {
  overflow: auto;

  @media (min-width: 1481px) {
    height: 287px;
  }
  @media (max-width: 1480px) {
    height: 187px;
  }

  &.contentUploaded {
    height: 120px;
  }
}

.deleteIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid var(--text_color);
  opacity: 0;
  visibility: hidden;
  transition: 0.15s all;
}

.crossIcon-wrap {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(224deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 98.55%);
}

.crossIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: var(--text_color);

  &:after {
    content: "";
    position: absolute;
  }
}

.topicMedia-container {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  background-color: var(--primary_color);
  border: 1px solid var(--border_color);
  padding: 16px 8px 16px 16px;
  display: flex;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  .topicMedia-scroll {
    max-height: 100%;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }
}

.topicMedia-empty {
  background-color: var(--primary_color);
  height: 100%;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 20px !important;
  }
}

.image_show_container_category {
  position: relative;
  border: 1px solid var(--border_color);
  border-radius: 4px;
  overflow: hidden;
  height: 200px;
  width: 300px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .deleteIcon {
    height: 28px;
    width: 28px;
    padding: 4px;
  }

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    .deleteIcon {
      opacity: 1;
      visibility: visible;
    }
  }
}

.image_show_container {
  position: relative;
  border: 1px solid var(--border_color);
  border-radius: 4px;
  overflow: hidden;

  @media (min-width: 1481px) {
    height: 152px;
  }
  @media (max-width: 1480px) {
    height: 122px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .deleteIcon {
    height: 28px;
    width: 28px;
    padding: 4px;
  }

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    .deleteIcon {
      opacity: 1;
      visibility: visible;
    }
  }
}

.uploadMedia_box {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.uploadMedia_grid {
  position: relative;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
}

.uploadIcon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  gap: 15px;
  background-color: var(--background_color);
  border-radius: 14px;

  @media (min-width: 1481px) {
    aspect-ratio: 1;
  }

  .topic_icon_upload {
    color: var(--text_color);
  }

  .ai_menu_icon {
    height: 50px;
    width: 55px;
  }

  .uploadIcon-title {
    text-align: center;
    border-radius: 50px;
    padding: 6px 10px;
    color: var(--text_color);
    background-color: var(--background_color);
    border: 1px solid var(--border_color);

    h4 {
      color: currentColor !important;
    }
  }

  &:hover {
    .uploadIcon-title {
      color: var(--background_color);
      background-color: var(--text_color);
    }
  }
}

.doc_view_container {
  position: relative;
  border: 1px solid var(--border_color);
  background-color: var(--background_color);
  border-radius: 4px;
  padding: 10px;

  .h4 {
    max-width: 70%;
  }

  .pdf_view_icon {
    color: var(--text_color);

    @media (min-width: 768px) {
      height: 38px;
      width: 38px;
    }
    @media (max-width: 767px) {
      height: 32px;
      width: 32px;
    }
  }

  .deleteIcon {
    height: 22px;
    width: 22px;
    padding: 2px;
  }

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

    .deleteIcon {
      opacity: 1;
      visibility: visible;
    }
  }
}

.menu_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  color: var(--heading_color);
  background-color: var(--background_color);
}

.menu_icon_container_selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--heading_color);
  color: var(--background_color);
}

.go_ai_button {
  background-color: var(--cta_color);
  color: var(--text_color);
  padding: 4px 10px;
  border-radius: 4px;
  transition: 0.15s all;

  @media (max-width: 700px) {
    padding: 5px 7px;
    font-size: 11px;
    line-height: 12.5px;
  }

  &:hover {
    color: var(--cta_color);
    background-color: var(--text_color);
  }
}

.generateAiImage-box {
  position: relative;
  border: 1px solid var(--border_color);
  background-color: var(--primary_color);
  border-radius: 12px;
  padding: 18px;
}

.aiBtn-container {
  position: relative;
  border-radius: 35px;
  height: 38px;
  padding: 0 12px 0 6px;
  border: 1px solid var(--border_color);
  background: var(--background_color);
  color: var(--heading_color);
  white-space: nowrap;

  .go_ai_text {
    font-size: 14px;
    font-weight: 700;
    color: currentColor;
  }

  &:hover {
    color: var(--cta_color);
    background-color: var(--text_color);
  }
}

.aiGeneration-view {
  position: relative;
  width: 100%;
  overflow: hidden;

  @media (min-width: 1481px) {
    height: 284px;
  }
  @media (max-width: 1480px) {
    height: 185px;
  }

  .aiGenerated-img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
  }

  .selectedIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 24px;
    width: 24px;
  }
}

.uploadVimeoVideo-view {
  width: 100% !important;
  background: var(--background_color);
  border-radius: 12px;
  border: 2px solid var(--border_color);
  overflow: hidden;

  @media (min-width: 1481px) {
    height: 295px;
    max-width: 526px !important;
  }
  @media (max-width: 1480px) {
    height: 190px;
    max-width: 326px !important;
  }

  iframe {
    height: 100%;
    width: 100%;
    white-space: nowrap;
  }

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
  }
}

.vimeoVideoShow-Box {
  position: relative;
  width: 100%;
  border-radius: 12px;
  background: var(--background_color);
  border: 2px solid var(--border_color);
  overflow: hidden;
  margin: 0 auto;

  @media (min-width: 1481px) {
    height: 201px;
  }
  @media (max-width: 1480px) {
    height: 171px;
  }
  @media (min-width: 768px) {
    max-width: 400px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: 250px;
  }

  & > div[data-vimeo-initialized="true"] {
    height: 100%;
    width: 100%;
  }

  iframe,
  video {
    height: 100%;
    width: 100%;
  }

  .mediaIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    color: var(--text_color);
    background-color: var(--background_color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .deleteIcon {
    height: 28px;
    width: 28px;
    padding: 4px;
  }

  &:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    .deleteIcon {
      opacity: 1;
      visibility: visible;
    }
  }
}

.create_topic_desktop_parent.modal-body {
  @media (min-width: 1481px) {
    height: 460px;
  }
  @media (max-width: 1480px) {
    height: 360px;
  }
}

// ====== Create Topic Mobile View CSS Start ======

.mdUpload-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  line-height: normal;
  border-radius: 50px;
  color: var(--text_color);
  background-color: var(--background_color);
  border: 1px solid var(--border_color);
  cursor: pointer;

  &:hover {
    color: var(--background_color);
    background-color: var(--text_color);
  }

  @media (min-width: 576px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    font-weight: 600;
  }
}

.mdCtaContainer {
  position: relative;
  border: 1px solid var(--border_color);
  background-color: var(--primary_color);
  border-radius: 12px;
  padding: 18px;
}

.mdImageView {
  position: relative;
  display: flex;
  align-items: center;
  overflow: auto;
  overflow-y: hidden;
  width: 100%;
  flex-wrap: nowrap;

  .col {
    min-width: 186px !important;
    width: 186px !important;
    max-width: 186px !important;
    flex: auto;
  }
}

.modal-body.create_a_topic {
  overflow-y: auto;
}

// ====== Create Topic Mobile View CSS End ======
