.redeme_card_container {
  border: 1px solid var(--border_color);
  border-radius: 20px;
  padding: 16px 34px 24px 34px;
}
.my_post_title {
  font-size: 16px;
  font-weight: 700;
}

.claim_description {
  position: relative;
  color: var(--text_color);
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (min-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (max-width: 767px) {
    font-size: 10px;
    line-height: 12px;
  }
}
