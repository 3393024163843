.right-sidebar-mini {
  left: 0;
  right: unset;
  transform: translateX(calc(1% + -1em));

  .ri-arrow-right-line:before {
    content: "\ea60";
  }
  .ri-arrow-left-line:before {
    content: "\ea6c";
  }
}
.right-sidebar-toggle {
  right: unset;
  left: 0;
  @if $enable-rounded == true {
    @include radius-mixin(0px 30px 30px 0px);
  }
}

.right-sidebar-mini.right-sidebar {
  -webkit-transform: translateX(calc(-90% + -2em));
  transform: translateX(calc(-90% + -2em));
}
